import React, { useState } from "react"
import { FaLongArrowAltRight } from "react-icons/fa"
import { encode } from "../utils"

/**
 * Contact Form Component
 * This component is used to generate the contact form page
 * This form is submitted to Netlify Forms using the name `contact-page`
 * The fields used in this form are
 *   - Name
 *   - Email
 *   - Subject
 *   - Message
 * @param {Object} props
 */
const ContactForm = ({ email, phone }) => {
  const [state, setState] = useState({
    success: false,
    error: false,
    loading: false,
  })
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    contact_subject: false,
    message: false,
  })
  const [form, setForm] = useState({
    "bot-field": "",
    name: "",
    email: "",
    contact_subject: "",
    message: "",
  })

  /**
   * Validate the form
   * Check if the required fields are filled
   *
   */
  const validate = () => {
    const validation = {
      name: form.name.length === 0,
      email: form.email.length === 0,
      contact_subject: form.contact_subject.length === 0,
      message: form.message.length === 0,
    }

    setErrors(validation)

    return (
      validation.name ||
      validation.email ||
      validation.contact_subject ||
      validation.message
    )
  }

  /**
   * Submit function
   * Execute the action when a form is submitted,
   *
   * @param {Object} event
   */
  const submit = event => {
    event.preventDefault()
    const hasErrors = validate()
    if (hasErrors) return

    setState({ ...state, loading: true })

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "subject": "Consulta de Cliente via Website",
        "form-name": "contact-page",
        ...form,
      }),
    })
      .then(() => setState({ ...state, loading: false, success: true }))
      .catch(() =>
        setState({
          ...state,
          loading: false,
          success: false,
          error: "There was an error submitting your form, please try again",
        })
      )
  }

  /**
   * On Change Form
   * Update the form state with the latest value of the field
   *
   * @param {Object} event
   */
  const onChangeForm = ({ target }) => {
    const { name, value } = target
    setForm({
      ...form,
      [name]: value,
    })
  }

  return (
    <form
      className="row"
      action="/contact-success/"
      name="contact-page"
      netlify-honeypot="bot-field"
      data-netlify="true"
      onSubmit={submit}
    >
      <div className="col-md-12">
        <div className={`contact-form__field ${errors.name ? "invalid" : ""}`}>
          <label className="sr-only" htmlFor="name">
            Your Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Your Name"
            value={form.name}
            onChange={onChangeForm}
          />
        </div>
      </div>
      <div className="col-md-12">
        <div className={`contact-form__field ${errors.email ? "invalid" : ""}`}>
          <label className="sr-only" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            value={form.email}
            onChange={onChangeForm}
          />
        </div>
      </div>
      <div className="col-md-12">
        <div
          className={`contact-form__field ${errors.contact_subject ? "invalid" : ""}`}
        >
          <label className="sr-only" htmlFor="phone">
            Subject
          </label>
          <input
            type="text"
            name="contact_subject"
            id="contact_subject"
            placeholder="Subject"
            value={form.contact_subject}
            onChange={onChangeForm}
          />
        </div>
      </div>
      <div className="col-md-12">
        <div
          className={`contact-form__field ${errors.message ? "invalid" : ""}`}
        >
          <label className="sr-only" htmlFor="message">
            Message
          </label>
          <textarea
            name="message"
            id="message"
            placeholder="Message"
            value={form.message}
            onChange={onChangeForm}
          ></textarea>
        </div>
      </div>

      <div className="col-md-12">
        <button
          type="submit"
          className="btn btn-red btn-hover-dark"
          disabled={state.loading}
        >
          Send Message &nbsp;&nbsp;&nbsp;
          <FaLongArrowAltRight />
        </button>
      </div>

      {state.error && (
        <div className="col-md-12">
          <p>{state.error}</p>
        </div>
      )}

      {state.success && (
        <div className="col-md-12">
          <p>{`The form was submitted succesfully`}</p>
        </div>
      )}
    </form>
  )
}

export default ContactForm
