import React from "react"
import { graphql } from "gatsby"
import {
  FaTwitter,
  FaGooglePlusG,
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contact"
import Hero from "../components/hero"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const contactPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
        address
      }
    }
    hero: file(relativePath: { eq: "pages/about/hero-contact.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const GOOGLE_API_KEY =
  process.env.GOOGLE_API_KEY || "AIzaSyAqaXyOqgPtOZEuV6aS1_oDXpgzejqPjQE"

/**
 * Contact Page Component
 * This component is used to generate the Contact page
 * This page can be accessible using the path `/contact/`
 *
 * @param {Object} props
 */
const ContactPage = ({ data }) => {
  const { address } = data.site.siteMetadata;
  const url = `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${address}&zoom=16`;

  return (
    <Layout isSecondary={true}>
      <SEO title="Contáctenos" description="" />

      <Hero
        type="simple"
        tagline="Haga su cita hoy"
        title="Contáctenos"
        background={data.hero.sharp.fluid}
      />

      <section className="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 contact-form__wrapper">
              <div className="row">
                <div className="col-lg-12 contact-header">
                  <h3 className="contact__title">Envíenos un mensaje</h3>
                  <p className="contact__text">
                   Servicios para los apreciables abogados y notarios en Guatemala,
                   que desean que le realicemos un mandato o cualquier otro documento legal.
                   En Estados Unidos. Favor contáctenos en el siguiente formulario.
                  </p>
                </div>
              </div>

              <ContactForm />
            </div>

            <div className="col-lg-5">
              <div className="row">
                <div className="col-lg-12 contact-header">
                  <h3 className="contact__title">Nuestras Oficinas</h3>                  
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <ul className="contact-details">
                    <li className="contact-detail">
                      <h4 className="contact-detail__title">USA</h4>
                      <p className="contact-detail__text">
                      88 Belvedere St. Suit 205 San Rafael California 94901
                      </p>
                      <p></p>
                      <h4 className="contact-detail__title">Guatemala</h4>
                      <p className="contact-detail__text">
                      3ra. Avenida 5-90 Edificio Plaza Escobar Oficina No. 2 Escuintla, Guatemala.
                      </p>
                    </li>                  
                    <li className="contact-detail">
                      <h4 className="contact-detail__title">Teléfonos</h4>
                      <p className="contact-detail__text">
                        Citas{" "}
                        <a href="tel:415-259-6040">
                          <strong>415 259-6040</strong>
                        </a>
                      </p>
                      <p className="contact-detail__text">
                        Información{" "}
                        <a href="tel:415-532-9880">
                          <strong>415 532-9880</strong>
                        </a>
                      </p>
                    </li>
                    <li className="contact-detail">
                      <h4 className="contact-detail__title">E-mail</h4>
                      <p className="contact-detail__text">
                        <a href="mailto:licclaveriaerick@hotmail.es">
                        licclaveriaerick@hotmail.es
                        </a>
                      </p>
                    </li>
                    <li className="contact-detail">
                      <h4 className="contact-detail__title">Horario de Oficina</h4>
                      <p className="contact-detail__text">
                        Lunes a Viernes : 09:00 am - 06:00 pm
                      </p>
                      <p className="contact-detail__text">
                        Sábado : 09:00 am - 01:00 pm
                      </p>
                    </li>
                    <li>
                      {/* <div className="contact-details__social">
                        <a href={`/`}>
                          <FaGooglePlusG className="social__google" />
                        </a>
                        <a href={`/`}>
                          <FaTwitter className="social__twitter" />
                        </a>
                        <a href={`/`}>
                          <FaFacebook className="social__facebook" />
                        </a>
                        <a href={`/`}>
                          <FaInstagram className="social__instagram" />
                        </a>
                        <a href={`/`}>
                          <FaLinkedinIn className="social__linkedin" />
                        </a>
                      </div> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-map">
        <iframe
          title={`Map for Contact page`}
          loading="lazy"
          frameBorder="0"
          style={{ border: false }}
          src={url}
          allowFullScreen
        />
      </section>
    </Layout>
  )
}

export default ContactPage
